import React from "react";
import BgGif from "../../assets/Images/Bg.gif";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Arrow from "../../assets/Images/Arrow.svg";
// import Team1 from "../../assets/Images/Team1.png";
// import Team2 from "../../assets/Images/Team2.png";
// import Team3 from "../../assets/Images/Team3.png";

// import LinkedinBlack from "../../assets/Images/LinkedinBlack.svg";

import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import Products from "./components/Products";
// import Clients from "./components/Clients";
import HomeContact from "../Contact/component/HomeContact";

const Home = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = "#fff";
  return (
    <>
      <Navbar />
      <div className="landing-section">
        <div className="bg-landing">
          <img src={BgGif} alt="" />
        </div>
        <div className="landing-text">
          <h1 className="style-title1">
            Only Human-Centric AI is <br /> <span> _Sustainable</span>
          </h1>
          <p className="style-subtitle-landing">
            At Hushl, we're a passionate team of tech innovators <br />{" "}
            dedicated to harnessing AI for positive impact.
          </p>
        </div>
        <div className="home-navogation">
          <div className="home-navogation-inner">
            <div className="home-nav-item home-read-more-about-text">
              <p
                onClick={() => {
                  navigate("/human-centric-ai");
                }}
                className="cta-type1-text"
              >
                Read more about <br />
                <span>_human centric AI</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div className="home-nav-item">
              <p
                className="cta-type1-text"
                onClick={() => {
                  navigate("/careers");
                }}
              >
                Explore working <br />
                <span>_with us</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Products />

      {/* <OurTeam /> */}

      {/* <Clients /> */}

      <HomeContact />

      <Footer />
    </>
  );
};

export default Home;
